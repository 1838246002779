<template>
  <div>
    <b-card>
      <h4>
          {{$t("Labels.UserInformation")}}
      </h4>
      <b-form
        class=""
        @submit.prevent
      >
      <validation-observer ref="userValidation">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Labels.Name')" label-for="name">
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-input id="name" v-model="user.name" :state="errors.length > 0 ? false:null" name="name" :placeholder="$t('Labels.Name')" maxlength="254"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Labels.LastName')" label-for="name">
            <validation-provider #default="{ errors }" name="lastName" rules="required">
              <b-form-input id="name" v-model="user.lastName" :state="errors.length > 0 ? false:null" name="name" :placeholder="$t('Labels.LastName')" maxlength="254"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Labels.Phone')" label-for="phone">
            <validation-provider #default="{ errors }" name="phone" rules="required|digits:10">
              <cleave class="form-control" id="phone" v-model="user.phone" :state="errors.length > 0 ? false:null" name="phone" :options="options.phoneMask" :placeholder="$t('Labels.Phone')"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="user.email"
                :state="errors.length > 0 ? false:null"
                name="email"
                :placeholder="$t('Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Password')"
            label-for="password"
          >
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required|password"
            >
              <b-form-input
                id="password"
                v-model="user.password"
                :state="errors.length > 0 ? false:null"
                name="password"
                :placeholder="$t('Password')"
                type="password"
              />
              <small class="text-danger">{{$t( errors[0] )}}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.ConfirmPassword')"
            label-for="confirmPassword"
          >
            <validation-provider
              #default="{ errors }"
              name="confirmPassword"
              rules="required|confirmed:password"
            >
              <b-form-input
                id="confirmPassword"
                v-model="user.confirmPassword"
                :state="errors.length > 0 ? false:null"
                name="confirmPassword"
                :placeholder="$t('Labels.ConfirmPassword')"
                type="password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group :label="$tc('Labels.SelectTracker')" label-for="device">
            <b-form-select name="device" :options="trackers" v-model="user.tracker_uuid" :reduce="v => v.uuid" value-field="uuid" text-field="name" :clearable="true">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" offset-md="6" class="d-flex justify-content-end">
          <b-col cols="6">
            <b-button type="submit" variant="primary" block @click="validationForm">{{$t("Labels.Save")}}</b-button>
            </b-col>
          &nbsp;
          <b-col cols="6">
            <b-button type="reset" block variant="danger" @click="cancel()">{{$t("Labels.Cancel")}}</b-button>
          </b-col>
        </b-col>
      </b-row>
      </validation-observer>
      </b-form>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    Cleave

  },
  data () {
    return {
      user: {},
      required,
      trackers: [],
      options: {
        phoneMask: {
          blocks: [3, 3, 4],
          uppercase: true
        }
      }

    }
  },
  methods: {
    cancel () {
      this.user = {}
      this.$router.push('/users')
    },
    async add () {
      if (!this.user.tracker_uuid) {
        this.user.tracker_uuid = null
      }
      const params = {
        url: 'tracker_users/carrier',
        method: 'POST',
        params: {
          email: this.user.email,
          password: btoa(this.user.password),
          repassword: btoa(this.user.confirmPassword),
          phone_number: this.user.phone,
          first_name: this.user.name,
          last_name: this.user.lastName,
          tracker_uuid: this.user.tracker_uuid
        }
      }
      await request(params).then(response => {
        this.user = {}
        this.$router.push('/users')
      })
    },
    validationForm () {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    },
    async getTrackers () {
      const params = {
        url: 'tracker/list?unassigned=2'
      }
      await request(params).then(response => {
        this.trackers = response.data.data
      }).catch(() => {
        this.trackers = []
      })
    }
  },
  created () {
    this.getTrackers()
  }
}
</script>
